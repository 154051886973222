import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const currentMonth = months[new Date().getMonth()];
const currentYear = new Date().getFullYear();

const menuLinks = [
  {name:"Home", link:"/"},
  {name:"Menu", link:"/menu"},
  {name:"Contact Us", link:"/contact"},
]

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#b61500`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        //maxWidth: 960,
        //padding: `1.45rem 1.0875rem`,
        //textAlign: "center",
        margin: "auto",
        width: "50%"
      }} id="scoped-content"
    >
      <style>
        {".nav-item:hover {background-color: #9A1200;}"}
      </style>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
            marginLeft:"auto", marginRight:"auto", width:"50%", display: "block"
          }}
        >
          <StaticImage
      src="../images/logo.png"
      //width={300}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem`}}
    />
        </Link>
        <h5
          style = {{color:"white", fontFamily: "Georgia", textAlign: "center"}}
        >Edible Panizzas since {currentMonth} {currentYear} 🌿</h5>
                <div>          
        </div>

        <nav style={{display: "table", margin:"0 auto"}}>
            <ul style={{ 
              listStyleType: "none",
              margin: "0, auto",
              padding: 0,
              overflow: "hidden"
             }}>
              {menuLinks.map(link => (
                <li
                  key={link.name}
                  style={{
                    float: "left",
                    fontFamily: "Georgia",
                    fontSize: 24,
                    fontWeight: "bold"
                  }}
                >
                  <Link style={{ 
                      display: "block",
                      color: `white`,
                      textAlign: "center",
                      padding: "14px 16px",
                      textDecoration: "None"
                    }} to={link.link}
                    class="nav-item"
                    activeClassName="nav-item"
                    activeStyle={{backgroundColor: "#9A1200"}}>
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
